// Sidebar.js
import React, { useState, useEffect, useRef } from 'react';
import KeywordButton from './KeywordButton';
import { useSelector, useDispatch } from 'react-redux';
import { downloadData, fetchCombinations, fetchInitialKeywords, addNewKeyword, addKeyword, removeKeyword, setKeywordName, setKeywordColor, updateOriginalKeywords } from './redux/actions';
import { toast } from 'react-toastify';
import { generatePastelColor } from './util/Colors';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CombinationButton from './CombinationButton'; // Import the new component
import html2canvas from 'html2canvas';

const Sidebar = ({ isOpen }) => {
  const dispatch = useDispatch();
  const originalKeywords = useSelector((state) => state.originalKeywords);
  const keywords = useSelector((state) => state.keywords);
  const [keywordsMode, setKeywordsMode] = useState("default");
  const [recolorActive, setRecolorActive] = useState(false);
  const [renameActive, setRenameActive] = useState(false);
  const [autodecompActive, setAutodecompActive] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const combinations = useSelector((state) => state.combinations);
  const addButtonRef = useRef(null);
  const deleteButtonRef = useRef(null);
  const renameButtonRef = useRef(null);
  const recolorButtonRef = useRef(null);
  const navigate = useNavigate();
  const isAllEnabledKeywordsOnly = false;
  const [loading, setLoading] = useState(false);
  const isAIMode = useSelector((state) => state.isAIMode);

  const sidebarStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '0 0 600px',
    maxWidth: '400px',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    textAlign: 'center',
    transition: 'flex 0.5s',
    display: isOpen ? 'block' : 'none',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center the buttons
    marginBottom: '10px',
  };

  const combinationContainerStyle = {
    display: 'block',
    justifyContent: 'center', // Center the buttons
    marginBottom: '10px',
  };

  const buttonStyle = {
    margin: '0 5px', // Add margin between buttons
    // padding: '8px 15px', // Add padding to the buttons
    padding: '4px 10px', // Add padding to the buttons
    borderRadius: '15px', // Add button border radius
    cursor: 'pointer', // Add pointer cursor on hover
    outline: 'none', // Remove outline on focus
    fontSize: '1.5em', // Increase button font size
    // border: 'none', // Remove button border
  };

  const recolorButtonStyle = {
    ...buttonStyle,
    backgroundColor: recolorActive ? '#999999' : buttonStyle.backgroundColor, // Change color when active
  };

  const renameButtonStyle = {
    ...buttonStyle,
    backgroundColor: renameActive ? '#999999' : buttonStyle.backgroundColor, // Change color when active
  };

  const deleteButtonStyle = {
    ...buttonStyle,
    backgroundColor: deleteMode ? '#ff6666' : buttonStyle.backgroundColor, // Change color when in delete mode
  };

  const howtoButtonStyle = {
    cursor: 'pointer',
    fontSize: '0.8em',
    textAlign: 'center',
    padding: '10px', // Optional: Add padding for better visibility
    borderTop: '1px solid #ddd', // Optional: Add a border at the top
  };


  const helpButtonContainerStyle = {
    display: 'flex',
    flexDirection: 'row', // Make buttons align horizontally
    justifyContent: 'center',
  };

  const helpButtonStyle = {
    cursor: 'pointer',
    color: 'green',
  };

  const downloadButtonStyle = {
    cursor: 'pointer',
    color: 'green',
  };

  const handleScreenshotClick = () => {
    html2canvas(document.body).then(canvas => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/jpeg');
      link.download = 'screenshot.jpg';
      link.click();
    });
  };


  useEffect(() => {
    // Fetch initial keywords from the server when the component mounts
    dispatch(fetchInitialKeywords());

    // Fetch existing convres keys when the component mounts
    dispatch(fetchCombinations());
  }, [dispatch]);

  const addButton = () => {
    setAllModesOff();

    // Delay the prompt to allow UI changes to take effect
    setTimeout(() => {
      // const newKeywordName = prompt('Enter a name for the new keyword:');
      const newKeywordName = prompt('새로운 분해대상을 추가해 보세요:');
      
      if (newKeywordName !== null && newKeywordName.trim() !== '') {
        // Generate a random color
        const randomColor = generatePastelColor();
    
        // Create a new keyword
        const newKeywordObject = {
          // id: originalKeywords.length,
          name: newKeywordName.trim(),
          // decomps: ["", "", "", ""],
          // decomps: [keyword.decomp1, keyword.decomp2, keyword.decomp3, keyword.decomp4],
          decomp1: "",
          decomp2: "",
          decomp3: "",
          decomp4: "",
          color: randomColor,
        };
    
        // // Dispatch an action to add the new keyword to the Redux store
        // dispatch(addKeyword(newKeyword));
    
        // // Dispatch an action to update originalKeywords in the Redux store
        // dispatch(updateOriginalKeywords([...originalKeywords, newKeyword]));


        // Dispatch an action to add the new keyword to the Redux store
        dispatch(addNewKeyword(newKeywordObject)).then((success) => {
          dispatch(fetchInitialKeywords());
        });
      } else {
        toast.warning('New keyword name cannot be empty');
      }
    }, 100);
  };

  const setAllModesOff = () => {
    setRecolorActive(false);
    setRenameActive(false);
    setDeleteMode(false);
    setAutodecompActive(false);
    setKeywordsMode('default');
  }

  const deleteButton = () => {
    setAllModesOff();
    setDeleteMode(!deleteMode); // Toggle delete mode

    const mode = deleteMode ? 'off' : 'on';
    // toast.success(`Delete mode is now ${mode}`);
    if (!deleteMode) setKeywordsMode('delete');
    else setKeywordsMode('default');
  };

  const renameButton = () => {
    setAllModesOff();
    setRenameActive(!renameActive);

    const mode = renameActive ? 'off' : 'on';
    // toast.success(`Rename mode is now ${mode}`);
    if (!renameActive) setKeywordsMode('rename');
    else setKeywordsMode('default');
  };


  const autodecompButton = () => {
    setAllModesOff();
    setAutodecompActive(!autodecompActive);

    const mode = autodecompActive ? 'off' : 'on';
    // toast.success(`Autodecomp mode is now ${mode}`);
    if (!autodecompActive) setKeywordsMode('autodecomp');
    else setKeywordsMode('default');
  };
  
  const recolorButton = () => {
    setAllModesOff();
    setRecolorActive(!recolorActive);

    const mode = recolorActive ? 'off' : 'on';
    // toast.success(`Recolor mode is now ${mode}`);
    if (!recolorActive) setKeywordsMode('recolor');
    else setKeywordsMode('default');
  };


  const CombinationButtons = () => {
    return (
      <div>
        {/* Display CombinationButtons */}
        <div>
          <h3>나의 융합 아이디어</h3>
          {/* <p>만들어진 융합 아이디어들은 여기에 저장됩니다.</p> */}
          <div style={combinationContainerStyle}>
            {
              combinations.length === 0 && <p style={{color:'gray'}}>(생성한 융합 아이디어가 없습니다.)</p>
            }
            {
              combinations.map((combination, index) => (
                <CombinationButton key={index} combination={combination} />
              ))
            }
          </div>
        </div>
      </div>
    );
  };

   // Extract username and nickname from localStorage
   const savedUsername = localStorage.getItem('username');
   const savedNickname = localStorage.getItem('nickname'); 


  // Redirect to login if username is not present in localStorage
  useEffect(() => {
    if (!savedUsername) {
      navigate('/login');
    }
  }, [navigate, savedUsername]);

  // Logout function
  const handleLogout = () => {
    // Display a confirmation prompt
    const userConfirmed = window.confirm('로그아웃 하시겠습니까?');

    // If the user confirms, proceed with logout
    if (userConfirmed) {
      // Clear username and nickname from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('nickname');

      // Navigate to login page
      navigate('/login');
    }
  };

  const handleHowToUseClick = () => {
    // toast.info('기능 개발중...');
    navigate('/howtouse');
  };

  //ctrl
  // const handleKeyboardShortcuts = (event) => {
  //   const { ctrlKey, key } = event;
  //   if (ctrlKey) {
  //     switch (key) {
  //       case '!':
  //         addButtonRef.current.click();
  //         break;
  //       case '2':
  //         deleteButtonRef.current.click();
  //         break;
  //       case '3':
  //         renameButtonRef.current.click();
  //         break;
  //       case '4':
  //         recolorButtonRef.current.click();
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };
  // shift+ctrl
  // const handleKeyboardShortcuts = (event) => {
  //   const { altKey, ctrlKey, key, shiftKey } = event;

  //   if (altKey && shiftKey) {
  //     switch (key) {
  //       case '1':
  //         addButtonRef.current.click();
  //         break;
  //       case '2':
  //         deleteButtonRef.current.click();
  //         break;
  //       case '3':
  //         renameButtonRef.current.click();
  //         break;
  //       case '4':
  //         recolorButtonRef.current.click();
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyboardShortcuts);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyboardShortcuts);
  //   };
  // }, []);

  // AI autodecomp things
  const autoDecompButtonStyle = {
    ...buttonStyle,
    backgroundColor: autodecompActive ? '#3399FF' : buttonStyle.backgroundColor,
    fontWeight: 'bold',
    padding: '0px 10px', // Add padding to the buttons
  };


  const handleDownloadDataClick = () => {
    // Perform the download data action
    // You can use the same logic as in your curl command
    // e.g., fetch('/api/users/download_data', { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } })
    // Handle the response or errors as needed
    // toast.info('데이터 다운로드 기능 개발 중...');
    dispatch(downloadData());
  };



  return (
    <div style={sidebarStyle}>

      <div>
         {/* Display username and nickname from localStorage */}
         {savedUsername && savedNickname && (
            <div style={{ marginTop: '10px', textAlign: 'center'}}>
              <span>
                환영합니다 <strong>{savedNickname}</strong> 님
              </span>
            </div>
          )}
      </div>

      <hr></hr>
      
      <>
        <h3>나의 분해대상</h3>
        {/* <p>자신만의 분해대상을 추가해 보세요.</p> */}

        <div style={buttonContainerStyle}>
          <button ref={addButtonRef} style={buttonStyle} onClick={addButton} title="분해대상 추가하기">
            ➕ 
          </button>
          <button ref={deleteButtonRef} style={deleteButtonStyle} onClick={deleteButton} title="분해대상 삭제하기">
            🗑️ 
          </button>
          <button ref={renameButtonRef} style={renameButtonStyle} onClick={renameButton} title="분해대상의 이름을 다시 정하기">
            ✏️ 
          </button>
          <button ref={recolorButtonRef} style={recolorButtonStyle} onClick={recolorButton} title="분해대상의 색상을 다시 정하기(랜덤)">
            🎨 
          </button>
          {isAIMode && (
          <button style={autoDecompButtonStyle} onClick={autodecompButton} title="분해대상을 인공지능(ChatGPT)으로 분해하기">
            &nbsp;AI&nbsp;
          </button>
          )}
        </div>

        {/* <div>
          <span>Action status : {keywordsMode}</span>
        </div> */}

        {
          originalKeywords.filter(keyword => keyword.enabled == true).length === 0 && <p style={{color:'gray'}}>(생성한 분해대상이 없습니다.)</p>
        }
        
        {originalKeywords
          .filter(keyword => keyword.enabled == true)
          .map((keyword, index) => (
            <KeywordButton key={keyword.id} keyword={keyword} keywordsMode={keywordsMode} setAllModesOff={setAllModesOff}></KeywordButton>
          ))}
      </>

      <hr />
      <CombinationButtons />

      {/* Spacer to push content to the top */}
      <div style={{ flex: 1 }} />

      <hr />
      {/* How to Use and Logout buttons */}
      <div style={helpButtonContainerStyle}>

        <div style={helpButtonStyle} onClick={handleHowToUseClick}>
          사용법
        </div>
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>|</div>
        <div style={downloadButtonStyle} onClick={handleDownloadDataClick}>
          엑셀다운
        </div>
        {/* <div style={{ marginLeft: '10px', marginRight: '10px' }}>|</div>
        <div style={helpButtonStyle} onClick={handleScreenshotClick}>
          스크린샷
        </div> */}
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>|</div>
        <div style={helpButtonStyle} onClick={handleLogout}>
          Logout
        </div>
      </div>

    </div>
  );
};

export default Sidebar;
