// App.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConvTable from './ConvTable';
import Sidebar from './Sidebar'; // Import the Sidebar component
import { ToastContainer } from 'react-toastify';
import { fetchInitialKeywords, setAIMode } from './redux/actions';
import 'react-toastify/dist/ReactToastify.css';
import html2canvas from 'html2canvas'; // Import html2canvas

const containerStyle = {
  display: 'flex',
  width: '100%',
  maxWidth: 'calc(100% - 20px * 2)',
  margin: '20px',
  border: '1px solid #ddd',
  overflowY: 'auto',
};

const contentStyle = {
  flex: '1', // Flexible width for the main content
};

const buttonStyle = {
  outline: 'none', // Make the outline invisible
  border: 'none',  // Remove border
  background: '#e0e0e0', // Subtle background color
  color: '#333', // Text color
  padding: '5px 10px', // Padding for better appearance
  cursor: 'pointer', // Add cursor pointer on hover
};

const buttonContainerStyle = {
  display: 'flex',
};

const App = () => {
  const dispatch = useDispatch();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [keySequence, setKeySequence] = useState('');
  const isAIMode = useSelector((state) => state.isAIMode);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Handle cheat code detection
  const handleKeyPress = (event) => {
    const newSequence = keySequence + event.key;
    setKeySequence(newSequence.slice(-6)); // Keep only the last 6 characters
    
    // Check for cheat codes
    if (newSequence.endsWith('aiaiai') || newSequence.endsWith('먀먀먀')) {
      if (!isAIMode) {
        dispatch(setAIMode(true));
        console.log('AI Mode activated!');
      }
    }
  };
  
  // Add key press event listener
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    
    // Cleanup function
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [keySequence, isAIMode]); // Dependencies for the useEffect

  const handleScreenshot = () => {
    // First collapse the hamburger menu if it's open
    if (isSidebarOpen) {
      setSidebarOpen(false);
    }
    
    // Store original text colors
    const elements = document.querySelectorAll('*');
    const originalColors = [];
    
    // Set all text to black
    elements.forEach(el => {
      originalColors.push({
        element: el,
        color: window.getComputedStyle(el).color
      });
      el.style.setProperty('color', 'black', 'important');
    });
    
    // Wait 300ms before taking the screenshot
    setTimeout(() => {
      html2canvas(document.body, {
        scale: 2, // Higher resolution
        logging: false // Reduce console noise
      }).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/jpeg', 1.0); // Use highest quality
        // YYYYMMDD_HHMMSS
        let timestamp = new Date().toISOString().split('T')[0]+'_'+new Date().toISOString().split('T')[1].replace(/:/g, '-');
        // remove tailing z and milisec
        timestamp = timestamp.replace(/\.0+$/, '');
        // remove tailing Z
        timestamp = timestamp.replace(/Z$/, '');

        link.download = 'conv_screenshot_'+timestamp+'.jpg';
        link.click();
        
        // Restore original colors
        originalColors.forEach(item => {
          item.element.style.color = item.color;
        });
        
        // Toggle sidebar back open after 500ms
        setTimeout(() => {
          setSidebarOpen(true);
        }, 1500);
      });
    }, 300);
  };

  return (
    <div style={containerStyle}>
      <Sidebar isOpen={isSidebarOpen} />
      <div style={contentStyle}>
        {/* Container for buttons */}
        <div style={buttonContainerStyle}>
          {/* Hamburger button to toggle sidebar visibility */}
          <button onClick={toggleSidebar} style={buttonStyle}>
            ☰
          </button>
          {/* Screenshot button */}
          <button onClick={handleScreenshot} style={{...buttonStyle, backgroundColor: '#E9E9E9'}} title="스크린샷">
            📷 스크린샷
          </button>
        </div>
        <ConvTable />
        {/* <ToastContainer position="bottom-right" autoClose={5000} /> */}
      </div>
    </div>
  );
};

export default App;
