// DecompCard.js
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateDecompOnServer, editDecomp } from './redux/actions'; // Add your action import
import { toast } from 'react-toastify';

const DecompCard = ({ keywordIndex, cellIndex }) => {
  const dispatch = useDispatch();
  const keywords = useSelector((state) => state.keywords);
  const keyword = useSelector((state) => state.keywords[keywordIndex]);

  const [isEditing, setEditing] = useState(false);
  const [editedText, setEditedText] = useState(keyword.decomps[cellIndex]);
  const textareaRef = useRef(null); // Create a ref for the textarea
  
  // Define default text value
  const defaultDecompText = "";

  useEffect(() => {
    if (isEditing) {
      setEditedText(keyword.decomps[cellIndex]);
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) {
      // Focus on the textarea when entering edit mode
      if (textareaRef.current) {
        textareaRef.current.focus();
        
        // No need for conditional clearing - just adjust height
        adjustTextareaHeight();
      }
    }
  }, [isEditing]); // Keep dependency array minimal

  // Separate effect for height adjustment
  useEffect(() => {
    if (isEditing && textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [editedText]);

  const cardStyle = {
    backgroundColor: keyword.color || '#ffcc66',
    padding: '10px',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    minWidth: '200px',
    cursor: 'pointer',
  };

  const titleStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '8px',
  };

  const decompListStyle = {
    listStyle: 'none',
    padding: '0',
    margin: '0',
    userSelect: 'none',
    whiteSpace: 'pre-line',
  };

  const decompTextareaStyle = {
    width: '100%',
    // minHeight: '60px',
    boxSizing: 'border-box',
    overflowY: 'hidden', // Hide vertical scrollbar
  };

  const handleSingleClick = (e) => {
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);

    // Check if the text has actually changed
    if (editedText !== keyword.decomps[cellIndex]) {
      // Dispatch the action to update the decomposition in the store
      dispatch(updateDecompOnServer(keyword.id, cellIndex, editedText))
        .then(success => {
          if (success) {
            // toast.success(`Updated decomp${cellIndex + 1} for keyword [${keyword.name}]`);
            toast.success(`융합대상 [${keyword.name}] 의 ${cellIndex + 1} 번째 분해항목을 수정하였습니다`);
          } else {
            toast.error('Failed to update decomposition on the server');
          }
        })
        .catch(error => {
          console.error('Error updating decomposition:', error);
          // Handle the error as needed
        });
    }else{
      console.log(`No change in decomp${cellIndex + 1}. skips updating.`);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset the textarea's height
      textarea.style.height = textarea.scrollHeight + 'px'; // Set new height
    }
  }

  const handleChange = (e) => {
    setEditedText(e.target.value);
    adjustTextareaHeight();
  };

  const handleKeyDown = (e) => {
    // Handle ESC key to finish editing and save
    if (e.key === 'Escape') {
      e.preventDefault();
      handleBlur(); // Calls the handleBlur function to save and exit edit mode
    }
    // Let ENTER behave normally (add new line)
    else if (e.key === 'Enter') {
      // Do nothing special, allow default behavior for Enter key
      // The textarea will automatically add new lines
    }
  };

  return (
    <div style={cardStyle} onClick={handleSingleClick}>
      {isEditing ? (
        <ul style={decompListStyle}>
          <li>
            <textarea
              ref={textareaRef}
              value={editedText}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              style={decompTextareaStyle}
            />
          </li>
        </ul>
      ) : (
        <>
          <div style={titleStyle}>{'[ ' + keyword.name + ' ]'}</div>
          <div style={decompListStyle}>
            <div>{keyword.decomps[cellIndex]}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default DecompCard;
