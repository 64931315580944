// Layout.js
import React, { useState, useEffect } from 'react';

const Layout = ({ children }) => {

  useEffect(() => {
    // Update the document title
    document.title = '융합보드 | WhyQ Academy';

    // You can also include variables or dynamic content in the title
    // For example, based on a state or props value
    // const dynamicTitle = 'Dynamic Title';
    // document.title = dynamicTitle;
  }, []); // The empty dependency array ensures the effect runs only once, similar to componentDidMount


  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {/* Header or other common elements can be added here */}
      <div style={{ flex: 1 }}>{children}</div>
      <div style={{ position: 'fixed', bottom: 5, right: 5}}>
        <span style={{ marginRight: 10 }}>version 1.2</span>
      </div>
      <footer style={{ textAlign: 'center', marginTop: '10px', padding: '5px', backgroundColor: '#f2f2f2' }}>
        © 2025 WhyQ Academy | Designed by Dr. Won, Seok-Yeon | Made by GQPHD | All rights reserved
      </footer>
    </div>
  );
};

export default Layout;
